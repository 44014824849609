<template>
    <article
        :class=" mobile ? 'col-lg-8' : 'col-md-3'"
        class="card card-stretch gutter-b justify-content-between mr-6 border-primary py-5 border-0"
    >
        <div class="d-flex flex-column justify-content-between">
            <span class="classe-img-container d-flex justify-content-center"
                  style="height:12rem; overflow:hidden">
                            <img class="classe-img" :src="classroom.classe.miniature" alt="image de la classe"/>
                        </span>
            <div class="card-header flex-column border-0 justify-content-center py-0 mt-5">
                <h3 class="card-title font-weight-bolder text-dark text-center mb-0">
                    {{ classroom.classe.title }}
                </h3>
                <p class="card-text text-muted text-center mb-0">
                    {{ classroom.tuteur.firstname }} {{ classroom.tuteur.lastname.toUpperCase() }}
                </p>
            </div>
            <div class="d-flex justify-content-center">
                <p class="m-0">Du <span class="font-weight-bold">{{ formatDate(classroom.start_date) }}</span> au <span class="font-weight-bold">{{ formatDate(classroom.end_date) }}</span></p>
            </div>
            <div class="classe-icon-container my-4">
                <div class="classe-icon">
                    <b-icon icon="person-fill" class="develop-card" style="color: #000;"></b-icon>
                    <p>{{ classroom.nb_stagiaires }}</p>
                </div>
                <div class="classe-icon">
                    <router-link :to="{ name: 'classroom', params: { id: classroom.id } }" replace>
                        <b-icon icon="play-circle-fill" class="develop-card" style="color: #000;"></b-icon>
                    </router-link>
                </div>
            </div>
        </div>
    </article>
</template>
<script>
import moment from "moment/moment";

export default {
    name: "SessionCard",
    props: {
        classroom: {
            type: Object,
            required: true
        },
    },
    computed: {
        mobile() {
            return window.screen.availWidth <= 992;
        }
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
    }
}
</script>
<style>
.classe-icon-container {
    display: flex;
    justify-content: space-evenly;
}

.classe-icon {
    display: flex;
    align-items: center;
}

.classe-icon p {
    margin-bottom: 0;
    margin-left: 0.8rem;
}

article {
    margin-bottom: 2vh;
}

article.card {
    box-shadow: 6px 6px 7px 4px rgba(184, 184, 184, 0.68);
    -webkit-box-shadow: 6px 6px 7px 4px rgba(184, 184, 184, 0.68);
    -moz-box-shadow: 6px 6px 7px 4px rgba(184, 184, 184, 0.68);
}

.classe-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.classe-img {
    max-width: 90%;
    max-height: 100%;
    width: auto;
    height: auto;
}

div.card-header {
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scrollPanel::-webkit-scrollbar {
    height: 2vh;
    background-color: #f1f1f1;
    z-index: 9999;
}

.scrollPanel::-webkit-scrollbar-thumb {
    background-color: #085a82;
    border-radius: 1em;
}

.displayScroll {
    height: 60vh;
    overflow-x: auto;
    flex-wrap: nowrap;
}

.displayScroll > article {
    min-width: 25em;
}

.displayWrap {
    padding: 2vh 0;
    height: auto;
    overflow-x: hidden;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start !important;
}

.displayWrap > article {
    margin-bottom: 5vh;
    min-width: 30em;
}

.bi {
    width: 2rem;
    height: 2rem;
}

.develop-card {
    cursor: pointer;
}

.customScroll {
    overflow-y: auto;
}

.customScroll::-webkit-scrollbar-track {
    background-color: #f3f6f9;
}

.customScroll::-webkit-scrollbar {
    width: 0.25em;
    background-color: #f3f6f9;
}

.customScroll::-webkit-scrollbar-thumb {
    background-color: #085a82;
}
</style>